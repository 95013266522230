<template>
  <div class="legalStatement">
    <h1>法律声明</h1>
    <div class="legalStatement1">
      <p class="legalStatement2">
        特别提醒： 请您在使用御之安科技网站( 以下简称“本网站” )
        前仔细阅读以下条款。若不接受以下条款请勿使用本网站；使用本网站则表明您已明知并接受如下条款；若违反以下条款，您使用本网站的授权将自动终止。
      </p>
      <p class="legalStatement3">
        1.知识产权声明<br />
        1.1 版权及信息使用
        御之安科技拥有本网站所载的所有内容的版权，但注明引用其他方的内容除外。任何被授权的浏览、复制、打印和传播属于本网站内的内容必须符合以下条件：
        本网站内容仅供个人非商业使用； 不得将本网站内容用于任何公开或商业目的；
        不得未经授权以任何目的将本网站内容或编辑形式用于其他网站、平面媒体或其他网络环境以及任何可能侵犯本网站版权的范畴；
        必须保留本版权声明。<br />
        1.2 商标权 本网站上使用和显示的所有商标、标识
        均属御之安科技所有，但注明属于其他方拥有的商标、标识及商号除外。未经事先书面许可，任何人不得以任何方式使用御之安科技的名称及其所有的商标、标识。
      </p>
      <p class="legalStatement4">
        2.信息发布
        本网站的信息不附加任何形式的保证，包括适销性、适合于特定目的或不侵犯知识产权的保证。同时，御之安科技对本网站信息客观上的真实性、准确性及完整性不提供任何保证和担保。
        御之安科技有权对本网站中的内容或在这些内容中介绍的业务、产品、技术信息等随时变更，恕不另行通知。本网站中的一些内容也可能已经过期，御之安科技不承诺您所浏览的信息是最新的信息。
      </p>
      <p class="legalStatement5">
        3.用户提交信息
        请勿通过本网站发送您的机密或专有信息。用户发送到本网站的任何信息、资料或联系方式
        ( 以下统称“用户信息” )
        等均被视为非保密和非专有信息，御之安科技不对此类信息承担任何义务或责任。同时，若您在提交时没有特别声明，则视为同意御之安科技及其被授权人因商业或非商业目的使用此类信息。
        用户不得违背法律法规及公众道德，向本网站或通过本网站发送任何侮辱、诽谤、威胁、淫秽、色情或其他任何可能违反法律的内容。本网站有权随时删除该等信息或无限时中止该信息的网上浏览，而不必事先取得提交者的同意，亦无义务事后通知提交者。
      </p>
      <p class="legalStatement6">
        4. 本网站下载软件的使用
        若您自本站的任何栏目下载软件，在使用软件时应遵守该软件携带的软件许可协议中所有的许可条款。在您阅读并接受前述软件许可协议的全部条款之前，不得下载或安装这一软件。
      </p>
      <p class="legalStatement7">
        5. 链接第三方网站
        本网站提供的指向第三方网站的链接仅为方便您使用。如果您使用这些链接，您将离开本网站。御之安科技没有全部审查过这些第三方网站，且对这些网站及其内容，御之安科技既无法控制也不承担任何责任。若您决定访问任何与本网站相链接的第三方网站，其可能产生的风险、结果及损害由您自行承担。
      </p>
      <p class="legalStatement8">
        6. 责任限制
        在任何情况下，御之安科技及其在本网站中所提及的任何第三方均不对用户链接到本网站，或使用本网站中的资料、信息或服务所引起的损害承担任何责任，包括但不限于利润损失、数据丢失或技术故障等所造成的损害。
      </p>
      <p class="legalStatement9">
        7. 其他 <br />7.1 特殊规定
        若本网站任何分栏目、页面或具体资料中的权利声明有特殊规定，与本公告中相关条文相抵触，从其特殊规定。
        <br />7.2 修改及解释
        御之安科技可依法随时修改这些条款。您应经常访问本页面以了解当前的条款，因为这些条款与您对本网站的使用密切相关。
        御之安科技对本公告及对本网站的使用拥有最终的解释权，对使用本网站时违反本公告的行为，绿盟科技将保留采取一切法律措施的权利。
        7.3 适用法律及争议解决
        因本公告或使用本网站所发生的争议均适用中华人民共和国法律。
        因本公告或使用本网站发生争议，应当协商解决，协商不成的，双方一致同意在绿盟科技公司所在地的人民法院诉讼解决。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.legalStatement {
  width: 100%;
  padding: 25px;
  h1 {
    text-align: center;
  }
  .legalStatement1 {
    margin: 0 auto;
    background: #ccc;
    padding: 20px;
    .legalStatement2 {
      font-size: 22px;
      font-weight: bold;
    }
    .legalStatement3 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
    .legalStatement4 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
    .legalStatement5 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
    .legalStatement6 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
    .legalStatement7 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
    .legalStatement8 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
    .legalStatement9 {
      padding-top: 20px;
      text-indent: 2rem;
      font-size: 18px;
    }
  }
}
</style>